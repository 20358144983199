import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

import Index from '../pages/index'
import Article from '../templates/article'
import About from '../pages/about'
import Services from '../pages/services'
import Journal from '../pages/journal'
import Contact from '../pages/contact'

const Preview = (props) => {
	const [data, setData] = useState(null);
    const [postType, setPostType] = useState(null);
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
                let data = response;
                
                console.log('data',data);
								
				setData(response)
				setPostType(data.post_type)
			})
	})
	
	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
            'page-home': Index,
            'page-about': About,
            'page-services': Services,
            'page-journal': Journal,
            'page-contact': Contact,
            'articles': Article,
            'services': Article,
		}

		const Component = templates[postType];

		return (
			<Component
                previewData={data}
                pageContext={data}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

